import { Theme, useTheme, Typography } from '@material-ui/core';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { generateSeededColour } from 'lib';
import * as React from 'react';
import { User } from 'interfaces';
import { useLocation } from 'hooks';
import { UserType } from 'enums';

interface Props extends Omit<AvatarProps, 'src'> {
  user?: User;
  name?: boolean;
  size?: number;
  fullName?: string;
  showLocation?: boolean;
}

export const UserAvatar = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      user,
      name = false,
      size = 40,
      style,
      fullName,
      showLocation = false,
      ...rest
    },
    ref,
  ) => {
    const {
      typography,
      palette: { getContrastText },
    } = useTheme<Theme>();

    const [locations] = useLocation();

    const avatarStyles: React.CSSProperties = {
      height: size,
      width: size,
      border: '2px solid #fff',

      // https://github.com/mui-org/material-ui/blob/3fd026bf1c511894ad5f52ca8cb1559b64d972a4/packages/material-ui/src/Avatar/Avatar.js#L17
      fontSize: typography.pxToRem((size / 40) * 20),
    };

    let avatar;
    const display = user ? `${user.name} ${user.surname}` : fullName ?? '';

    if (!display) {
      avatar = <Avatar style={avatarStyles} {...rest} />;
    } else {
      const bgColor = `#${generateSeededColour({ seed: display })}`;

      avatar = (
        <React.Fragment>
          <Avatar
            alt={display}
            style={{
              ...avatarStyles,
              backgroundColor: bgColor,
              color: getContrastText(bgColor),
            }}
            {...rest}
          >
            {display
              .match(/\b[A-Z]/gi)
              ?.slice(0, 2)
              .join('')
              .toUpperCase()}
          </Avatar>
          {name ? (
            <div>
              <Typography
                variant="h6"
                color="inherit"
                style={{
                  lineHeight:
                    locations && user?.userType.id !== UserType.Manager
                      ? 1.2
                      : 2.3,
                  paddingLeft: '7px',
                }}
              >
                {display}
              </Typography>
              <Typography color="inherit" style={{ paddingLeft: '7px' }}>
                {showLocation &&
                locations?.length &&
                user?.userType.id !== UserType.Manager
                  ? locations[0].location.name
                  : ''}
              </Typography>
            </div>
          ) : (
            ''
          )}
        </React.Fragment>
      );
    }

    return (
      <div
        style={{
          display: 'inline-flex',
          ...style,
          position: 'relative',
          paddingTop: '2px',
        }}
        ref={ref}
      >
        {avatar}
      </div>
    );
  },
);
