import { Translation } from 'interfaces';

export function translate(translations: Translation[], defaultText: string) {
  const translation = translations.find(
    (t) => t.originalText.localeCompare(defaultText) === 0,
  );

  if (translation) {
    return translation.translatedText;
  }

  return defaultText;
}
