import {
  AppBar,
  Dialog,
  DialogProps,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useGlobalState } from 'App.state';
import { LanguageList } from 'enums';
import { Language } from 'interfaces';
import Cookies from 'js-cookie';
import * as React from 'react';
import { LanguageService } from 'services';
import { useStyles } from './styles';

interface Props {
  dialogProps: DialogProps;
}

export const SelectLanguageDialog: React.FC<Props> = ({ dialogProps }) => {
  const classes = useStyles();

  const [languages, setLanguages] = React.useState<Language[]>([]);
  const [language, setLanguage] = useGlobalState('language');

  React.useEffect(() => {
    LanguageService.getLanguages().then((response) => {
      setLanguages(response);
    });
  }, []);

  return (
    <Dialog fullWidth open={dialogProps.open} onClose={dialogProps.onClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={dialogProps.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Select a language
          </Typography>
        </Toolbar>
      </AppBar>
      <List>
        {languages.map((lang, key) => (
          <React.Fragment key={key}>
            <ListItem
              button
              onClick={() => selectLanguage(lang.id.toString())}
              disabled={Boolean(
                lang.id.toString() ===
                  (language || LanguageList.ENGLISH.toString()),
              )}
            >
              <ListItemText primary={lang.name} />
            </ListItem>
            {key !== languages.length - 1 ? <Divider /> : ''}
          </React.Fragment>
        ))}
      </List>
    </Dialog>
  );

  function selectLanguage(languageId: string) {
    Cookies.set('language', languageId, {
      expires: 30,
      path: '/',
    });

    setLanguage(languageId);

    if (dialogProps.onClose) {
      dialogProps.onClose({});
    }
  }
};
