import * as React from 'react';
import { useInterval } from 'react-interval-hook';
import { useList } from 'react-firebase-hooks/database';
import { User, Translation, FirebaseBatch } from 'interfaces';
import { FirebaseService } from 'services';
import { EmptyView, Batch } from 'components';
import { usePrevious } from 'hooks';
import { UserType } from 'enums';
import beepSound from '../beep.mp3';

interface Props {
  shopLocationIds: number[];
  user?: User;
  translations: Translation[];
}

export const Togo: React.FC<Props> = ({
  shopLocationIds,
  user,
  translations,
}) => {
  const [batches] = useList(FirebaseService.batches());
  const [currentBatch, setCurrentBatch] = React.useState<FirebaseBatch>();
  const [locationBatches, setLocationBatches] = React.useState<FirebaseBatch[]>(
    [],
  );

  const [currentBatchAmount, setCurrentBatchAmount] = React.useState(-1);

  const prevBatch = usePrevious(batches);

  const { start, stop } = useInterval(
    () => {
      if (!batches) {
        return;
      }

      const today = new Date();
      const futureBatches = batches.filter(
        (b) =>
          shopLocationIds.includes(b.val().shop_location_id) &&
          new Date(b.val().batch_closes_at).getTime() > today.getTime(),
      );

      if (futureBatches.length === 0) {
        stop();
      }

      let newBatches: FirebaseBatch[] = batches.map((b) => b.val());

      //If user is not a manager, get only batches in current location
      if (user && user.userType.id !== UserType.Manager) {
        const newBatchesList = batches.filter(
          (b) =>
            shopLocationIds.includes(b.val().shop_location_id) &&
            new Date(b.val().batch_closes_at).getTime() < today.getTime(),
        );

        newBatches = newBatchesList.map((b) => b.val());
      }

      setLocationBatches([...newBatches]);
    },
    1000,
    {
      autoStart: false,
      immediate: false,
      selfCorrecting: false,
    },
  );

  React.useEffect(() => {
    if (
      currentBatchAmount !== -1 &&
      currentBatchAmount < locationBatches.length
    ) {
      new Audio(beepSound).play();
    }

    setCurrentBatchAmount(locationBatches.length);
  }, [currentBatchAmount, locationBatches.length]);

  React.useEffect(() => {
    if (!batches) {
      return;
    }

    const today = new Date();
    let newBatches: FirebaseBatch[] = batches.map((b) => b.val());

    //If user is not a manager, get only batches in current location
    if (user && user.userType.id !== UserType.Manager) {
      const newBatchesList = batches.filter(
        (b) =>
          shopLocationIds.includes(b.val().shop_location_id) &&
          new Date(b.val().batch_closes_at).getTime() < today.getTime(),
      );

      newBatches = newBatchesList.map((b) => b.val());
    }

    setLocationBatches(newBatches);

    start();
  }, [batches, shopLocationIds, user, start]);

  React.useEffect(() => {
    if (!locationBatches || locationBatches.length === 0 || !user) {
      if (currentBatch !== prevBatch) {
        setCurrentBatch(undefined);
      }
    }
  }, [currentBatch, locationBatches, prevBatch, user]);

  React.useEffect(() => {
    const tempCurrentBatch = getCurrentBatch();

    if (prevBatch === tempCurrentBatch) {
      return;
    }

    setCurrentBatch(tempCurrentBatch);

    function getCurrentBatch() {
      if (!user) {
        return undefined;
      }

      let tempBatches = locationBatches.filter(
        (batch) => batch.time_start && batch.assignee_id === user.id,
      )[0];

      if (tempBatches) {
        return tempBatches;
      }

      tempBatches = locationBatches.filter(
        (batch) => batch.assignee_id === user.id,
      )[0];

      if (tempBatches) {
        return tempBatches;
      }

      return locationBatches.filter((batch) => !batch.time_start)[0];
    }
  }, [locationBatches, prevBatch, user]);

  return currentBatch && locationBatches && user ? (
    <Batch
      batch={currentBatch}
      batches={locationBatches}
      translations={translations}
      changeBatch={(batch: FirebaseBatch) => setCurrentBatch(batch)}
      user={user}
      onDone={() => {
        setCurrentBatchAmount(currentBatchAmount - 1);
        setCurrentBatch(undefined);
      }}
    />
  ) : (
    <EmptyView>
      {shopLocationIds
        ? 'There are currently no batches assigned to this location'
        : 'Please choose a location to start'}
    </EmptyView>
  );
};
