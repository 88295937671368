import * as React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { FirebaseJob, Translation, User } from 'interfaces';
import { FirebaseService } from 'services';
import { JobTabs, JobInterface } from 'components';

interface Props {
  jobId: number;
  jobList: number[];
  translations: Translation[];
  changeJob: (job: number) => void;
  onDone: () => void;
  user: User;
}

export const Job: React.FC<Props> = ({
  jobId,
  jobList,
  translations,
  changeJob,
  onDone,
  user,
}) => {
  const [job, loading] = useObjectVal<FirebaseJob>(FirebaseService.job(jobId));

  return job ? (
    <React.Fragment>
      <JobInterface
        job={job}
        user={user}
        translations={translations}
        onDone={onDone}
        loading={loading}
      />
      <JobTabs
        loading={loading}
        job={job}
        user={user}
        jobList={jobList}
        translations={translations}
        changeJob={changeJob}
      />
    </React.Fragment>
  ) : null;
};
