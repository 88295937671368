import * as React from 'react';
import { FirebaseJob, User, Translation } from 'interfaces';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  makeStyles,
  CardHeader,
  Grid,
  List,
} from '@material-ui/core';
import { TimeAgo, SnackbarContext, Note, TranslatedButton } from 'components';
import { OrderService } from 'services';
import { JobStatusId, UserType } from 'enums';
import { translate } from 'lib';
import { Loading } from 'components/Loading';
import { useHideDescription } from 'hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  loadingContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 5,
    backgroundColor: '#ffffff87',
  },
  spinner: {
    margin: 'auto',
    top: '13%',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '48%',
  },
}));

interface Props {
  job: FirebaseJob;
  user: User;
  translations: Translation[];
  onDone: () => void;
  loading: boolean;
}

export const JobInterface: React.FC<Props> = ({
  job,
  onDone,
  user,
  translations,
  loading: isLoading,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(isLoading);
  const [hideDescription] = useHideDescription();
  const [showNote, setShowNote] = React.useState(false);

  React.useEffect(() => {
    setShowNote(false);
  }, [job]);

  return (
    <Card className={classes.root} variant="outlined">
      <Loading loading={loading} />
      <CardHeader
        title={`${translate(translations, job?.jobName)} x ${job.quantity}`}
        variant="h1"
      />
      <CardContent>
        {!hideDescription && (
          <Typography color="textSecondary" gutterBottom variant="h3">
            {translate(translations, job.description)}
          </Typography>
        )}
        <List>
          {showNote && <Typography variant="h6">Notes</Typography>}
          {Object.values(job.jobOrderItem).map((item, key) => (
            <Note
              jobOrderItemId={item._id}
              currentJob={job}
              key={key}
              translations={translations}
              showNote={(show: boolean) => {
                if (!showNote) {
                  setShowNote(show);
                }
              }}
            />
          ))}
        </List>
        <Grid container spacing={2}>
          <Grid item sm={job.time_start ? 6 : 12}>
            <Typography variant="h6" align="center">
              Time since job received
            </Typography>
            <TimeAgo
              time={job.job_created}
              variant="h1"
              align="center"
              color="secondary"
            />
          </Grid>
          {job.time_start && job.time_start.toString().length === 13 ? (
            <Grid item sm={6}>
              <Typography variant="h6" align="center">
                Time since job started
              </Typography>
              <TimeAgo
                time={job.time_start}
                variant="h1"
                align="center"
                loading={loading}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </CardContent>
      {user.userType.id === UserType.Chef ? (
        <CardActions>
          {job.status_id === JobStatusId.Pending ? (
            <TranslatedButton
              buttonProps={{
                fullWidth: true,
                size: 'large',
                variant: 'contained',
                color: 'secondary',
                onClick: () => startOrder(),
              }}
              buttonText="Start"
              translations={translations}
            />
          ) : (
            <TranslatedButton
              buttonProps={{
                fullWidth: true,
                size: 'large',
                variant: 'contained',
                color: 'secondary',
                onClick: () => doneJob(),
              }}
              buttonText="Done"
              translations={translations}
            />
          )}
        </CardActions>
      ) : (
        <CardContent>
          <Typography variant="h6">
            {job.assignee_name
              ? `Assigned Chef: ${job.assignee_name}`
              : 'No chef assigned yet'}
          </Typography>
        </CardContent>
      )}
    </Card>
  );

  async function doneJob() {
    setLoading(true);
    const response = await OrderService.doneOrder(job.jobId);

    if (response.error) {
      snackbar.error(response.message);
      setLoading(false);
      return;
    }

    onDone();
    setLoading(false);
  }

  async function startOrder() {
    setLoading(true);
    const response = await OrderService.startOrder(job.jobId);

    if (response.error) {
      snackbar.error(response.message);
    }

    setLoading(false);
  }
};
