import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel, Loading } from 'components';
import { FirebaseJob, Translation, User } from 'interfaces';
import { Typography } from '@material-ui/core';
import { JobComponent } from './components';
import { JobOrderItem } from './components/JobOrderItem';
import { UserType } from 'enums';

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tabPanel: {
    position: 'relative',
  },
}));

interface Props {
  job: FirebaseJob;
  jobList: number[];
  translations: Translation[];
  changeJob: (job: number) => void;
  loading: boolean;
  user: User;
}

export const JobTabs: React.FC<Props> = ({
  job,
  jobList,
  translations,
  changeJob,
  loading,
  user,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const otherJobs = jobList.filter((tempJobId) => tempJobId !== job.jobId);

  return (
    <React.Fragment>
      <AppBar position="relative" className={classes.tab}>
        <Tabs
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          textColor="primary"
          indicatorColor="primary"
        >
          {user.userType.id === UserType.Chef ? (
            <Tab label="Related jobs" {...tabProps(0)} />
          ) : (
            ''
          )}
          <Tab label="Other jobs" {...tabProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.tabPanel}>
          <Loading loading={loading} />
          {Object.values(job.jobOrderItem).length > 0 ? (
            Object.values(job.jobOrderItem).map((item, key) => (
              <JobOrderItem
                key={key}
                id={item._id}
                currentJob={job}
                translations={translations}
                changeJob={changeJob}
                user={user}
              />
            ))
          ) : (
            <Typography>No related jobs</Typography>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.tabPanel}>
          <Loading loading={loading} />
          {otherJobs.length > 0 ? (
            otherJobs.map((otherJob, key) => (
              <JobComponent
                id={otherJob}
                key={key}
                currentJob={job}
                translations={translations}
                changeJob={changeJob}
                loading={loading}
                user={user}
              />
            ))
          ) : (
            <Typography>No other jobs</Typography>
          )}
        </div>
      </TabPanel>
    </React.Fragment>
  );
};
