import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from 'components';
import { Translation, Preparation, User } from 'interfaces';
import { Typography, Grid } from '@material-ui/core';
import { PreparationCard } from '../PreparationCard';

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tabPanel: {
    position: 'relative',
  },
}));

interface Props {
  preparation: Preparation;
  preparationList: Preparation[];
  translations: Translation[];
  changePreparation: (batch: Preparation) => void;
  user: User;
}

export const PrepTab: React.FC<Props> = ({
  preparation,
  preparationList,
  changePreparation,
  user,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const otherPreparations = preparationList.filter(
    (temp) => temp.id !== preparation.id,
  );

  return (
    <React.Fragment>
      <AppBar position="relative" className={classes.tab}>
        <Tabs
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Other Preparations" {...tabProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.tabPanel}>
          <Grid container spacing={1}>
            {otherPreparations.length > 0 ? (
              otherPreparations.map((otherPreparation, key) => (
                <Grid item sm={4}>
                  <PreparationCard
                    key={key}
                    currentPreparation={preparation}
                    preparation={otherPreparation}
                    changePreparation={changePreparation}
                    user={user}
                  />
                </Grid>
              ))
            ) : (
              <Typography>No other preparations</Typography>
            )}
          </Grid>
        </div>
      </TabPanel>
    </React.Fragment>
  );
};
