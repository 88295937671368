import * as React from 'react';
import { Translation, PreparationJob } from 'interfaces';
import {
  Typography,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { translate } from 'lib';

interface Props {
  job: PreparationJob;
  translations: Translation[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  loadingContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 5,
    backgroundColor: '#ffffff87',
  },
  spinner: {
    margin: 'auto',
    top: '13%',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '48%',
  },
}));

export const PreparationJobCard: React.FC<Props> = ({ job, translations }) => {
  const classes = useStyles();

  return job ? (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title={`${job.quantity} ${job.metric} of ${translate(
          translations,
          job.preparation_item,
        )}`}
        variant="h4"
      />
      <CardContent>
        <Typography color="textSecondary" gutterBottom variant="h5">
          {translate(translations, job.notes)}
        </Typography>
      </CardContent>
    </Card>
  ) : null;
};
