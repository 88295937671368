import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FirebaseJob, Translation, User } from 'interfaces';
import { Grid, CardActionArea, List } from '@material-ui/core';
import { UserAvatar, TimeAgo, ConfirmDialog, Note } from 'components';
import { JobStatusId, UserType } from 'enums';
import { translate } from 'lib';
import { Loading } from 'components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  complete: {
    color: theme.palette.secondary.main,
  },
  progress: {
    color: theme.palette.danger.main,
  },
  notStarted: {
    color: theme.palette.warning.main,
  },
  tab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
}));

interface Props {
  job: FirebaseJob;
  currentJob: FirebaseJob;
  translations: Translation[];
  changeJob: (jobId: number) => void;
  user: User;
  loading: boolean;
}

export const JobCard: React.FC<Props> = ({
  job,
  currentJob,
  changeJob,
  translations,
  loading,
  user,
}) => {
  const classes = useStyles();
  const [timeCreated] = React.useState(job.job_created);
  const [timeStart] = React.useState(job.time_start);
  const [endTime] = React.useState(job.end_time);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const [showNote, setShowNote] = React.useState(false);

  React.useEffect(() => {
    setShowNote(false);
  }, [job]);

  return (
    <Card className={classes.root}>
      <Loading loading={loading} size={30} />
      <CardActionArea
        onClick={() => getResponse(job, currentJob, setDialog, changeJob, user)}
      >
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <CardContent className={classes.flex}>
              <UserAvatar fullName={job.assignee_name} />
              <div className={classes.flexColumn}>
                <Typography>{`${translate(translations, job?.jobName)} x ${
                  job.quantity
                }`}</Typography>
                <List>
                  {showNote && <Typography variant="h6">Notes</Typography>}
                  {Object.values(job.jobOrderItem).map((item, key) => (
                    <Note
                      jobOrderItemId={item._id}
                      currentJob={job}
                      key={key}
                      translations={translations}
                      showNote={(show: boolean) => {
                        if (!showNote) {
                          setShowNote(show);
                        }
                      }}
                    />
                  ))}
                </List>
                <Typography className={getClassnameByStatus(job.status_id)}>
                  {getLabelByStatus(job.status_id)}
                </Typography>
                {user.userType.id === UserType.Manager ? (
                  <Typography>
                    {job.assignee_name
                      ? `Assigned Chef: ${job.assignee_name}`
                      : 'No chef assigned yet'}
                  </Typography>
                ) : (
                  ''
                )}
              </div>
            </CardContent>
          </Grid>
          <Grid item sm={6}>
            <CardContent className={classes.flex}>
              {job.status_id !== JobStatusId.Complete ? (
                <div className={classes.flexColumn}>
                  <Typography style={{ fontSize: '9px' }} align="center">
                    Time since job received
                  </Typography>
                  <TimeAgo
                    style={{ fontSize: '25px' }}
                    align="center"
                    time={timeCreated}
                    color="secondary"
                  />
                </div>
              ) : (
                ''
              )}
              {timeStart &&
              job.status_id === JobStatusId.Complete &&
              endTime ? (
                <div className={classes.flexColumn}>
                  <Typography style={{ fontSize: '9px' }} align="center">
                    Time since job received
                  </Typography>
                  <TimeAgo
                    style={{ fontSize: '25px' }}
                    align="center"
                    time={timeStart}
                    color="secondary"
                    tick={true}
                  />
                </div>
              ) : (
                ''
              )}
              {timeStart && job.status_id !== JobStatusId.Complete ? (
                <div className={classes.flexColumn}>
                  <Typography style={{ fontSize: '9px' }} align="center">
                    Time since job started
                  </Typography>
                  <TimeAgo
                    style={{ fontSize: '25px' }}
                    align="center"
                    time={timeStart}
                    color="secondary"
                  />
                </div>
              ) : (
                ''
              )}
              {timeStart &&
              endTime &&
              job.status_id === JobStatusId.Complete ? (
                <div className={classes.flexColumn}>
                  <Typography style={{ fontSize: '9px' }} align="center">
                    Time taken to finish job
                  </Typography>
                  <TimeAgo
                    style={{ fontSize: '25px' }}
                    align="center"
                    time={timeStart}
                    endTime={endTime}
                    color="secondary"
                    tick={true}
                  />
                </div>
              ) : (
                ''
              )}
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
      <ConfirmDialog
        title={text}
        dialogProps={{ open: open, onClose: () => setDialog('', false) }}
        noText="Ok"
      />
    </Card>
  );

  function setDialog(text: string, open: boolean) {
    setText(text);
    setOpen(open);
  }

  function getClassnameByStatus(status: number): string {
    switch (status) {
      case JobStatusId.Complete:
        return classes.complete;
      case JobStatusId.InProgress:
        return classes.progress;
      default:
        return classes.notStarted;
    }
  }

  function getLabelByStatus(status: number): string {
    switch (status) {
      case JobStatusId.Complete:
        return 'Job completed';
      case JobStatusId.InProgress:
        return 'Job in progress';
      default:
        return 'Job not started';
    }
  }
};
function getResponse(
  job: FirebaseJob,
  currentJob: FirebaseJob,
  setDialog: (text: string, open: boolean) => void,
  changeJob: (jobId: number) => void,
  user: User,
): void {
  if (currentJob.time_start) {
    setDialog('Please finish your current job before starting a new one', true);
    return;
  }

  if (job.shop_location_id !== currentJob.shop_location_id) {
    setDialog(
      'This job is from another location, please start another job',
      true,
    );
    return;
  }

  switch (job.status_id) {
    case JobStatusId.Pending:
      changeJob(job.jobId);
      break;
    case JobStatusId.InProgress:
      if (job.assignee_id !== user.id)
        setDialog(
          'This job is already in progress by someone else, please start another job',
          true,
        );
      break;
    case JobStatusId.Complete:
      setDialog(
        'This job is already completed, please start another job',
        true,
      );
      break;
    default:
      break;
  }
}
