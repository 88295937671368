import { FirebaseJob, Translation, User } from 'interfaces';
import * as React from 'react';
import { FirebaseService } from 'services';
import { useObjectVal } from 'react-firebase-hooks/database';
import { JobCard } from 'components';

interface Props {
  id: number;
  currentJob: FirebaseJob;
  translations: Translation[];
  loading?: boolean;
  changeJob: (jobId: number) => void;
  user: User;
}

export const JobComponent: React.FC<Props> = ({
  id,
  currentJob,
  translations,
  changeJob,
  loading = false,
  user,
}) => {
  const [job] = useObjectVal<FirebaseJob>(FirebaseService.job(id));

  return job ? (
    <JobCard
      currentJob={currentJob}
      job={job}
      translations={translations}
      changeJob={changeJob}
      loading={loading}
      user={user}
    />
  ) : null;
};
