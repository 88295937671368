import * as React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  loadingContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 5,
    backgroundColor: '#ffffff87',
  },
  spinner: {
    margin: 'auto',
    top: '13%',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '48%',
  },
}));

interface Props {
  loading: boolean;
  size?: number;
}

export const Loading: React.FC<Props> = ({ loading, size }) => {
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <div className={classes.loadingContainer}>
      <CircularProgress size={size ?? 80} className={classes.spinner} />
    </div>
  );
};
