import { ApiClient } from 'ApiClient';
import { Language } from 'interfaces';

class LanguageService {
  public async getLanguages() {
    return ApiClient.get<Language[]>('languages');
  }
}

const singleton = new LanguageService();

export { singleton as LanguageService };
