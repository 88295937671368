import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0, 0.5, 2, 0.5),
  },
  cardActions: {
    display: 'block',
    textAlign: 'right',
    paddingTop: 0,
    paddingRight: theme.spacing(2),
  },
  cardHeader: {
    background: 'none',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.main,
  },
  footNote: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
    textAlign: 'right',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  paymentPeriod: {
    fontSize: '90%',
  },
  moduleItem: {
    paddingRight: 80,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  readMore: {
    fontSize: '90%',
  },
  readMoreLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  totalYearly: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 4,
  },
  taxNote: {
    fontStyle: 'italic',
    fontSize: 12,
  },
  text: {
    fontSize: 12,
  },
  cell: {
    border: '1px solid black',
    padding: theme.spacing(1),
  },
  table: {
    borderCollapse: 'collapse',
  },
  introParagraph: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));
