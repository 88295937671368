import * as React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import {
  FirebaseJob,
  User,
  Translation,
  FirebaseShopLocation,
} from 'interfaces';
import { FirebaseService } from 'services';
import { JobStatusId } from 'enums';
import { Job } from 'components/Job';
import { EmptyView } from 'components';
import useSound from 'use-sound';
import beepSound from '../beep.mp3';

interface Props {
  shopLocationIds: number[];
  user?: User;
  translations: Translation[];
}

export const Streetfood: React.FC<Props> = ({
  shopLocationIds,
  user,
  translations,
}) => {
  const [jobId, setJobId] = React.useState<number>();
  const [jobsCount, setJobCount] = React.useState<number>();
  const [jobs] = useObjectVal<FirebaseJob[]>(FirebaseService.jobs());
  const [play] = useSound(beepSound);

  const [firebaseLocationsReference] = useObjectVal<FirebaseShopLocation[]>(
    FirebaseService.shopLocations(),
  );

  const firebaseJobs =
    firebaseLocationsReference && firebaseLocationsReference.length > 0
      ? firebaseLocationsReference
          .filter((locations, index) => shopLocationIds.includes(index))
          .map((ref) => ref.jobs)
      : [];

  const firebaseJobIds: number[] = [];

  firebaseJobs.forEach((jobs) => {
    firebaseJobIds.concat(Object.values(jobs).map((job) => job._id));
  });

  React.useEffect(() => {
    if (jobs && Object.values(jobs).length > 0) {
      if (!jobsCount && jobsCount !== undefined) {
        setJobCount(Object.values(jobs).length);
        if (Object.values(jobs).length === 1) {
          play();
        }
      }

      if (jobsCount) {
        if (Object.values(jobs).length > jobsCount) {
          play();
          setJobCount(jobs.length);
        } else {
          setJobCount(jobs.length);
        }

        if (jobsCount <= Object.values(jobs).length) {
          setJobCount(Object.values(jobs).length);
        }
      }
    } else {
      setJobCount(0);
    }
  }, [jobs, jobsCount, play]);

  React.useEffect(() => {
    if (shopLocationIds && jobs && !jobId) {
      const jobsArray = Object.values(jobs);
      const activeJob = jobsArray.find(
        (job) =>
          job.status_id === JobStatusId.InProgress &&
          job.assignee_id === user?.id &&
          shopLocationIds.includes(job?.shop_location_id), //to check
      );

      if (activeJob) {
        setJobId(activeJob?.jobId);
      } else {
        const firstjob = jobsArray
          .filter(
            (job) =>
              job?.status_id === JobStatusId.Pending &&
              shopLocationIds.includes(job?.shop_location_id), //to check
          )
          .sort((job1, job2) => {
            if (job1.job_created > job2.job_created) {
              return 1;
            }
            if (job1.job_created < job2.job_created) {
              return -1;
            }
            return 0;
          })[0];
        setJobId(firstjob?.jobId ?? undefined);
      }
    }
  }, [jobId, jobs, shopLocationIds, user]);

  return shopLocationIds && jobId && user ? (
    <Job
      jobId={jobId}
      jobList={firebaseJobIds}
      translations={translations}
      changeJob={(jobId: number) => {
        setJobId(jobId);
      }}
      user={user}
      onDone={() => {
        setJobId(undefined);
      }}
    />
  ) : (
    <EmptyView>
      {shopLocationIds
        ? 'There are currently no jobs assigned to this location'
        : 'Please choose a location to start'}
    </EmptyView>
  );
};
