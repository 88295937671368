import {
  FirebaseJobOrderItem,
  FirebaseOrder,
  FirebaseJob,
  Translation,
} from 'interfaces';
import * as React from 'react';
import { FirebaseService } from 'services';
import { useObjectVal } from 'react-firebase-hooks/database';
import { ListItem, ListItemText } from '@material-ui/core';
import { translate } from 'lib';

interface Props {
  jobOrderItemId: number;
  currentJob: FirebaseJob;
  translations: Translation[];
  showNote: (show: boolean) => void;
}

export const Note: React.FC<Props> = ({
  jobOrderItemId,
  currentJob,
  translations,
  showNote,
}) => {
  const [jobOrderItem] = useObjectVal<FirebaseJobOrderItem>(
    FirebaseService.jobOrderItem(jobOrderItemId),
  );

  const [order] = useObjectVal<FirebaseOrder>(
    FirebaseService.orders(jobOrderItem?.orderNo ?? 0),
  );

  const show =
    order && order.orderitems
      ? Object.values(order.orderitems).filter((item) => {
          const notes = Object.values(item.jobs).filter(
            (job, key) => job._id === currentJob.jobId && item.notes,
          );

          return notes.length > 0;
        }).length > 0
      : false;

  showNote(show);

  return order && order.orderitems ? (
    <React.Fragment>
      {Object.values(order.orderitems).map((item, key) =>
        jobOrderItem &&
        item.orderItemNo === parseInt(jobOrderItem.orderItemKey, 10) ? (
          <ListItem key={key}>
            <ListItemText
              primary={`Quantity: ${item.quantity}`}
              secondary={
                item.notes
                  ? `Note: ${translate(translations, item.notes)}`
                  : undefined
              }
            />
          </ListItem>
        ) : null,
      )}
    </React.Fragment>
  ) : null;
};
