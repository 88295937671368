import { useGlobalState } from 'App.state';
import camelcaseKeys from 'camelcase-keys';
import { SnackbarContext } from 'components';
import { LanguageList, Shops, UserType } from 'enums';
import { useLocation } from 'hooks';
import { FirebaseLanguage, Translation, User } from 'interfaces';
import * as React from 'react';
import { FirebaseService, UserService } from 'services';
import { logout } from 'lib';
import { Streetfood, Togo, PreparationInterface } from './components';
import { useList } from 'react-firebase-hooks/database';

export const Dashboard: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [locations] = useLocation();
  const [user, setUser] = React.useState<User>();
  const [translations, setTranslations] = React.useState<Translation[]>([]);
  const [firebaseTranslations] = useList(FirebaseService.allTranslations());

  const [language] = useGlobalState('language');

  const shopId = locations && locations.length > 0 ? locations[0].shopId : 0;
  const shopLocationIds =
    locations && locations.length > 0
      ? locations.map((location) => location.id)
      : [];

  const loadTranslations = React.useCallback(
    async function loadTranslations(languageId: string) {
      try {
        const tempTranslations: FirebaseLanguage[] = firebaseTranslations
          ? firebaseTranslations.map((t) => t.val())
          : [];

        const values = tempTranslations.filter(
          (t) =>
            t.translations &&
            Object.values(t.translations).some(
              (tt) => tt.language_id.toString() === languageId,
            ),
        );

        const convertedValues: Translation[] = values.length
          ? Object.values(values[0].translations).map(
              ({ language_id, original_text, translated_text }) => ({
                languageId: language_id,
                originalText: original_text,
                translatedText: translated_text,
              }),
            )
          : [];

        const translations = convertedValues
          ? camelcaseKeys(Object.values<Translation>(convertedValues))
          : [];

        if (languageId === LanguageList.CHINESE.toString()) {
          translations.push(
            {
              languageId: 2,
              originalText: 'Done',
              translatedText: '完成了',
            },
            {
              languageId: 2,
              originalText: 'Start',
              translatedText: '开始',
            },
          );
        }

        setTranslations(translations);

        FirebaseService.translations(languageId).on(
          'child_changed',
          (response) => {
            setTranslations((trans) => {
              const newValue: Translation = camelcaseKeys(response.val());
              const newTranslations = trans.slice();

              const index = newTranslations.findIndex(
                (t) => t.originalText === newValue.originalText,
              );

              if (newTranslations[index]) {
                newTranslations[index].translatedText = newValue.translatedText;
              }

              return newTranslations;
            });
          },
        );
      } catch (error) {
        if (error.message.indexOf('permission_denied') !== -1) {
          logout();
          return;
        }

        snackbar.error(error);
      }
    },
    [firebaseTranslations, snackbar],
  );

  React.useEffect(() => {
    loadTranslations(language || LanguageList.ENGLISH.toString());
  }, [language, loadTranslations]);

  React.useEffect(() => {
    UserService.getUserWithToken().then((response) => {
      setUser(response);
    });
  }, []);

  return user && user.userType.id === UserType.Preparator ? (
    <PreparationInterface
      shopLocationIds={shopLocationIds}
      user={user}
      translations={translations}
    />
  ) : shopId && shopId === Shops.Streetfood ? (
    <Streetfood
      shopLocationIds={shopLocationIds}
      user={user}
      translations={translations}
    />
  ) : (
    <Togo
      shopLocationIds={shopLocationIds}
      user={user}
      translations={translations}
    />
  );
};
