import { ApiClient } from 'ApiClient';
import { UserType, User } from 'interfaces';

class UserService {
  public async getUsers() {
    return ApiClient.get<User[]>('users');
  }

  public async getUserTypes() {
    return ApiClient.get<UserType[]>('user-types');
  }

  public async getUserWithToken() {
    return ApiClient.get<User>('users/with-token');
  }
}

const singleton = new UserService();

export { singleton as UserService };
