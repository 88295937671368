import { ApiClient } from 'ApiClient';

class PreparationService {
  public async startPreparation(id: number) {
    return ApiClient.put(`preparations/start/${id}`);
  }

  public async donePreparation(id: number) {
    return ApiClient.put(`preparations/completed/${id}`);
  }
}

const singleton = new PreparationService();

export { singleton as PreparationService };
