import * as React from 'react';
import { Translation, User, Preparation } from 'interfaces';
import { PrepInterface, PrepTab } from './components';

interface Props {
  preparation: Preparation;
  preparations: Preparation[];
  translations: Translation[];
  changePreparation: (batch: Preparation) => void;
  onDone: () => void;
  user: User;
}

export const PreparationComponent: React.FC<Props> = ({
  preparation,
  preparations,
  translations,
  changePreparation,
  onDone,
  user,
}) => {
  return preparation ? (
    <React.Fragment>
      <PrepInterface
        preparation={preparation}
        translations={translations}
        onDone={onDone}
      />
      <PrepTab
        preparation={preparation}
        preparationList={preparations}
        translations={translations}
        changePreparation={changePreparation}
        user={user}
      />
    </React.Fragment>
  ) : null;
};
