import * as React from 'react';
import { useList } from 'react-firebase-hooks/database';
import { User, Translation, Preparation } from 'interfaces';
import { FirebaseService } from 'services';
import { EmptyView, PreparationComponent } from 'components';
import { usePrevious } from 'hooks';

interface Props {
  shopLocationIds: number[];
  user?: User;
  translations: Translation[];
}

export const PreparationInterface: React.FC<Props> = ({
  shopLocationIds,
  user,
  translations,
}) => {
  const [preprations] = useList(FirebaseService.preparations());
  const [currentPrep, setCurrentPrep] = React.useState<Preparation>();
  const [locationPrep, setLocationPrep] = React.useState<Preparation[]>([]);

  const prevPrep = usePrevious(currentPrep);

  React.useEffect(() => {
    if (!preprations) {
      return;
    }

    const newPrepList = preprations.filter((b) =>
      shopLocationIds.includes(b.val().shop_location_id),
    );

    const newPreps: Preparation[] = newPrepList.map((b) => b.val());
    setLocationPrep(newPreps);
  }, [preprations, shopLocationIds]);

  const getCurrentPreparation = React.useCallback(
    function getCurrentPreparation() {
      if (!user) {
        return undefined;
      }

      let tempBatches = locationPrep.filter(
        (prep) => prep.start && prep.assignee_id === user.id,
      )[0];

      if (tempBatches) {
        return tempBatches;
      }

      tempBatches = locationPrep.filter(
        (prep) => prep.assignee_id === user.id,
      )[0];

      if (tempBatches) {
        return tempBatches;
      }

      return locationPrep.filter((prep) => !prep.start)[0];
    },
    [locationPrep, user],
  );

  React.useEffect(() => {
    if (!locationPrep || locationPrep.length === 0 || !user) {
      return;
    }

    const tempCurrentPrep = getCurrentPreparation();

    if (prevPrep === tempCurrentPrep) {
      return;
    }

    setCurrentPrep(tempCurrentPrep);
  }, [getCurrentPreparation, locationPrep, prevPrep, user]);

  return currentPrep && locationPrep && user ? (
    <PreparationComponent
      preparation={currentPrep}
      preparations={locationPrep}
      translations={translations}
      changePreparation={(prep: Preparation) => {
        setCurrentPrep(prep);
      }}
      user={user}
      onDone={() => {
        setCurrentPrep(undefined);
      }}
    />
  ) : (
    <EmptyView>
      {shopLocationIds
        ? 'There are currently no preparations assigned to this location'
        : 'Please choose a location to start'}
    </EmptyView>
  );
};
