import {
  FirebaseJobOrderItem,
  FirebaseOrder,
  FirebaseJob,
  Translation,
  User,
} from 'interfaces';
import * as React from 'react';
import { FirebaseService } from 'services';
import { useObjectVal } from 'react-firebase-hooks/database';
import { JobComponent } from './JobComponent';

interface Props {
  id: number;
  currentJob: FirebaseJob;
  translations: Translation[];
  changeJob: (jobId: number) => void;
  user: User;
}

export const JobOrderItem: React.FC<Props> = ({
  id,
  currentJob,
  translations,
  changeJob,
  user,
}) => {
  const [jobOrderItem] = useObjectVal<FirebaseJobOrderItem>(
    FirebaseService.jobOrderItem(id),
  );

  const [order, loading] = useObjectVal<FirebaseOrder>(
    FirebaseService.orders(jobOrderItem?.orderNo ?? 0),
  );

  return order && order.orderitems ? (
    <React.Fragment>
      {Object.values(order.orderitems).map((item) =>
        Object.values(item.jobs).map((job, key) =>
          job._id !== currentJob.jobId ? (
            <JobComponent
              currentJob={currentJob}
              id={job._id}
              key={key}
              translations={translations}
              changeJob={changeJob}
              loading={loading}
              user={user}
            />
          ) : (
            ''
          ),
        ),
      )}
    </React.Fragment>
  ) : null;
};
