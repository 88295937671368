import { ApiClient } from 'ApiClient';

class OrderService {
  public async startOrder(id: number) {
    return ApiClient.put(`orders/start-job/${id}`);
  }

  public async doneOrder(id: number) {
    return ApiClient.put(`orders/job-done/${id}`);
  }

  public async startBatch(id: number) {
    return ApiClient.put(`orders/start-batch/${id}`);
  }

  public async doneBatch(id: number) {
    return ApiClient.put(`orders/done-batch/${id}`);
  }
}

const singleton = new OrderService();

export { singleton as OrderService };
