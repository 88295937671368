import * as React from 'react';
import { Translation, FirebaseBatch } from 'interfaces';
import {
  Card,
  CardContent,
  CardActions,
  makeStyles,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import { SnackbarContext, TranslatedButton } from 'components';
import { OrderService } from 'services';
import { Loading } from 'components/Loading';
import { BatchJob } from '../BatchJob';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  loadingContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 5,
    backgroundColor: '#ffffff87',
  },
  spinner: {
    margin: 'auto',
    top: '13%',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '48%',
  },
}));

interface Props {
  batch: FirebaseBatch;
  translations: Translation[];
  onDone: () => void;
}

export const BatchInterface: React.FC<Props> = ({
  batch,
  onDone,
  translations,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item sm={4}>
        <Card className={classes.root} variant="outlined">
          <Loading loading={loading} />
          <CardHeader
            title={`Batch Job #${batch.batch_number ?? 1}`}
            variant="h1"
          />
          <CardContent>
            <Typography variant="subtitle2">
              Batch Recieved at: {batch.batch_closes_at}
            </Typography>
          </CardContent>
          <CardActions>
            {batch.time_start ? (
              <TranslatedButton
                buttonProps={{
                  fullWidth: true,
                  size: 'large',
                  variant: 'contained',
                  color: 'secondary',
                  onClick: () => doneBatch(),
                }}
                buttonText="Done"
                translations={translations}
              />
            ) : (
              <TranslatedButton
                buttonProps={{
                  fullWidth: true,
                  size: 'large',
                  variant: 'contained',
                  color: 'secondary',
                  onClick: () => startBatch(),
                }}
                buttonText="Start"
                translations={translations}
              />
            )}
          </CardActions>
        </Card>
      </Grid>
      <Grid item sm={8}>
        <Grid container spacing={1}>
          {Object.values(batch.jobs).map((job, key) => (
            <Grid item sm={6} key={key}>
              <BatchJob translations={translations} jobId={job._id} key={key} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  async function doneBatch() {
    try {
      setLoading(true);
      const response = await OrderService.doneBatch(batch.id);

      if (response.error) {
        throw new Error(response.message);
      }

      onDone();
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function startBatch() {
    setLoading(true);
    const response = await OrderService.startBatch(batch.id);

    if (response.error) {
      snackbar.error(response.message);
    }

    setLoading(false);
  }
};
