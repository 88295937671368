import { useGlobalState } from 'App.state';
import Cookies from 'js-cookie';
import { Config } from 'config';

export function useHideDescription(): [
  number,
  (newHideDescription: number) => void,
] {
  const [storedLocation, setStoredLocation] = useGlobalState('hideDescription');

  const hideDescription = parseInt(storedLocation, 10);

  const handleUpdateHideDescription = (newHideDescription: number) => {
    setStoredLocation(JSON.stringify(newHideDescription));

    Cookies.set('hideDescription', JSON.stringify(newHideDescription), {
      expires: 0.02,
      path: '/',
      domain: Config.COOKIE_DOMAIN,
    });
  };

  return [hideDescription, handleUpdateHideDescription];
}
