import * as React from 'react';
import { Translation, User, FirebaseBatch } from 'interfaces';
import { BatchInterface } from './components/BatchInterface/BatchInterface';
import { BatchTab } from './components';

interface Props {
  batch: FirebaseBatch;
  batches: FirebaseBatch[];
  translations: Translation[];
  changeBatch: (batch: FirebaseBatch) => void;
  onDone: () => void;
  user: User;
}

export const Batch: React.FC<Props> = ({
  batch,
  batches,
  translations,
  changeBatch,
  onDone,
  user,
}) => {
  return batch ? (
    <React.Fragment>
      <BatchInterface
        batch={batch}
        translations={translations}
        onDone={onDone}
      />
      <BatchTab
        batch={batch}
        batchList={batches}
        changeBatch={changeBatch}
        user={user}
      />
    </React.Fragment>
  ) : null;
};
