import * as React from 'react';
import { timeAgo } from 'lib';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps {
  time: number;
  endTime?: number;
  tick?: boolean;
  loading?: boolean;
}

export const TimeAgo: React.FC<Props> = ({
  time,
  tick = true,
  endTime,
  loading = false,
  ...typographyProps
}) => {
  const [formattedTime, setTime] = React.useState(timeAgo(time, endTime));

  const promise = setInterval(() => {
    if (tick && !loading) {
      setTime(timeAgo(time, endTime));
    }
  }, 900);

  React.useEffect(() => {
    return () => {
      clearInterval(promise);
    };
  }, [promise]);

  return <Typography {...typographyProps}>{formattedTime}</Typography>;
};
