import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from 'components';
import { FirebaseBatch, User } from 'interfaces';
import { Typography, Grid } from '@material-ui/core';
import { BatchCard } from '../BatchCard';

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tabPanel: {
    position: 'relative',
  },
}));

interface Props {
  batch: FirebaseBatch;
  batchList: FirebaseBatch[];
  changeBatch: (batch: FirebaseBatch) => void;
  user: User;
}

export const BatchTab: React.FC<Props> = ({
  batch,
  batchList,
  changeBatch,
  user,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const otherBatches = batchList.filter(
    (tempBatch) => tempBatch.id !== batch.id,
  );

  return (
    <React.Fragment>
      <AppBar position="relative" className={classes.tab}>
        <Tabs
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Other Batches" {...tabProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.tabPanel}>
          <Grid container spacing={1}>
            {otherBatches.length > 0 ? (
              otherBatches.map((otherBatch, key) => (
                <Grid item sm={4} key={key}>
                  <BatchCard
                    key={key}
                    currentBatch={batch}
                    batch={otherBatch}
                    changeBatch={changeBatch}
                    user={user}
                  />
                </Grid>
              ))
            ) : (
              <Typography>No other batches</Typography>
            )}
          </Grid>
        </div>
      </TabPanel>
    </React.Fragment>
  );
};
