import { Config } from 'config';
import firebase from 'firebase';
import 'firebase/database';

class FirebaseService {
  config = {
    apiKey: Config.FIREBASE.API_KEY,
    databaseURL: Config.FIREBASE.DATABASE_URL,
    projectId: Config.FIREBASE.PROJECT_ID,
    authDomain: Config.FIREBASE.AUTH_DOMAIN,
    storageBucket: Config.FIREBASE.STORAGE_BUCKET,
    messagingSenderId: Config.FIREBASE.MESSAGING_SENDER_ID,
    appId: Config.FIREBASE.APP_ID,
    measurementId: Config.FIREBASE.MEASURMENT_ID,
  };

  constructor() {
    firebase.initializeApp(this.config);
  }

  shopLocations = () => firebase.database().ref(`shoplocations`);
  job = (id: number) => firebase.database().ref(`jobs/${id}`);
  jobs = () => firebase.database().ref(`jobs`);
  batches = () => firebase.database().ref(`batches`);
  preparations = () => firebase.database().ref(`preparations`);
  batchesId = (id: number) => firebase.database().ref(`batches/${id}`);
  jobOrderItem = (id: number) => firebase.database().ref(`joborderitems/${id}`);
  jobOrderItems = () => firebase.database().ref(`joborderitems`);
  orders = (id: number) => firebase.database().ref(`orders/${id}`);
  translations = (languageId: string) =>
    firebase.database().ref(`languages/${languageId}/translations`);

  allTranslations = () => firebase.database().ref(`languages`);

  public getFirebase() {
    return firebase;
  }

  public getConfig() {
    return this.config;
  }
}

const singleton = new FirebaseService();

export { singleton as FirebaseService };
