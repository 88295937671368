export function timeAgo(unixTime: number, endTime?: number) {
  const startTime = Math.round(new Date(unixTime).getTime());

  const secondsAgo = endTime
    ? Math.round(new Date(endTime).getTime()) - startTime
    : Math.round(new Date().getTime()) - unixTime;

  const date = new Date(secondsAgo);

  if (secondsAgo < 0) {
    return '00:00';
  }

  if (secondsAgo > 3600000) {
    return `${date.getHours()}:${
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    }:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
  }

  return `${
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  }:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
}
