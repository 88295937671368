import * as React from 'react';
import { FirebaseJob, Translation } from 'interfaces';
import {
  Typography,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  List,
} from '@material-ui/core';
import { Note } from 'components';
import { FirebaseService } from 'services';
import { translate } from 'lib';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useHideDescription } from 'hooks';

interface Props {
  jobId: number;
  translations: Translation[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  loadingContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 5,
    backgroundColor: '#ffffff87',
  },
  spinner: {
    margin: 'auto',
    top: '13%',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '48%',
  },
}));

export const BatchJob: React.FC<Props> = ({ jobId, translations }) => {
  const classes = useStyles();
  const [job] = useObjectVal<FirebaseJob>(FirebaseService.job(jobId));
  const [showNote, setShowNote] = React.useState(false);
  const [hideDescription] = useHideDescription();

  React.useEffect(() => {
    setShowNote(false);
  }, [job]);

  return job ? (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title={`${translate(translations, job.jobName)} x ${job.quantity}`}
        variant="h4"
      />
      <CardContent>
        {!hideDescription && (
          <Typography color="textSecondary" gutterBottom variant="h5">
            {translate(translations, job.description)}
          </Typography>
        )}
        <List>
          {showNote && <Typography variant="h6">Notes</Typography>}
          {Object.values(job.jobOrderItem).map((item, key) => (
            <Note
              jobOrderItemId={item._id}
              currentJob={job}
              key={key}
              translations={translations}
              showNote={(show: boolean) => {
                if (!showNote) {
                  setShowNote(show);
                }
              }}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  ) : null;
};
