import * as React from 'react';
import {
  DialogProps,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { useStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { ShopLocation } from 'interfaces';

interface Props {
  dialogProps: DialogProps;
  locations: ShopLocation[];
  onSelect: (location: ShopLocation) => void;
}

export const LocationChooserDialog: React.FC<Props> = ({
  dialogProps,
  locations,
  onSelect,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth open={dialogProps.open} onClose={dialogProps.onClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={dialogProps.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Choose a location
          </Typography>
        </Toolbar>
      </AppBar>
      <List>
        {locations.map((location, key) => (
          <React.Fragment key={key}>
            <ListItem button onClick={() => onSelect(location)}>
              <ListItemText primary={location.location.name} />
            </ListItem>
            {key !== locations.length - 1 ? <Divider /> : ''}
          </React.Fragment>
        ))}
      </List>
    </Dialog>
  );
};
