import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FirebaseBatch, User } from 'interfaces';
import { CardActionArea } from '@material-ui/core';
import { UserAvatar, ConfirmDialog } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  complete: {
    color: theme.palette.secondary.main,
  },
  progress: {
    color: theme.palette.danger.main,
  },
  notStarted: {
    color: theme.palette.warning.main,
  },
  tab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
}));

interface Props {
  batch: FirebaseBatch;
  currentBatch: FirebaseBatch;
  changeBatch: (batch: FirebaseBatch) => void;
  user: User;
}

export const BatchCard: React.FC<Props> = ({
  batch,
  currentBatch,
  changeBatch,
  user,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() =>
          getResponse(batch, currentBatch, setDialog, changeBatch, user)
        }
      >
        <CardContent className={classes.flex}>
          <UserAvatar fullName={batch.assignee_name} />
          <div className={classes.flexColumn}>
            <Typography variant="h5">{`Batch Job #${batch.batch_number ??
              1}`}</Typography>
            <Typography variant="subtitle2">
              Batch Recieved at: {batch.batch_closes_at}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <ConfirmDialog
        title={text}
        dialogProps={{ open: open, onClose: () => setDialog('', false) }}
        noText="Ok"
      />
    </Card>
  );

  function setDialog(text: string, open: boolean) {
    setText(text);
    setOpen(open);
  }
};

function getResponse(
  batch: FirebaseBatch,
  currentBatch: FirebaseBatch,
  setDialog: (text: string, open: boolean) => void,
  changeBatch: (batch: FirebaseBatch) => void,
  user: User,
): void {
  if (batch.shop_location_id !== currentBatch.shop_location_id) {
    setDialog(
      'This batch is from another location, please start another batch',
      true,
    );
    return;
  }

  if (batch.time_start && batch.assignee_id !== user.id) {
    setDialog(
      'This batch is already in progress by someone else, please start another batch',
      true,
    );

    return;
  }

  changeBatch(batch);
  return;
}
