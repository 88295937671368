import { useGlobalState } from 'App.state';
import Cookies from 'js-cookie';
import { Config } from 'config';
import { ShopLocation } from 'interfaces';

export function useLocation(): [
  ShopLocation[] | undefined,
  (newLocation: ShopLocation[] | undefined) => void,
] {
  const [storedLocation, setStoredLocation] = useGlobalState('location');
  const location: ShopLocation[] | undefined = storedLocation
    ? (JSON.parse(storedLocation) as ShopLocation[])
    : undefined;

  const handleUpdateLocation = (newLocation: ShopLocation[] | undefined) => {
    let locationJSON = '';

    if (newLocation) {
      locationJSON = JSON.stringify(newLocation);
    }

    setStoredLocation(locationJSON);

    Cookies.set('location', locationJSON, {
      expires: 0.02,
      path: '/',
      domain: Config.COOKIE_DOMAIN,
    });
  };

  return [location, handleUpdateLocation];
}
