import { Shop } from 'interfaces';
import { ApiClient } from 'ApiClient';

class ShopService {
  public async getShops() {
    return ApiClient.get<Shop[]>('shops');
  }

  public async getLocationsByShipId(id: number) {
    return ApiClient.get(`shop-locations/by-shop/${id}`);
  }
}

const singleton = new ShopService();

export { singleton as ShopService };
