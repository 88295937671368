import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import * as React from 'react';
import { AppRouteProps } from '../../routes';
import { useStyles } from './styles';
import { UserService, ShopService } from 'services';
import {
  User,
  ShopLocation,
  ShopAllocation,
  LocationAllocation,
} from 'interfaces';
import {
  UserAvatar,
  ShopChooserDialog,
  LocationChooserDialog,
  SelectLanguageDialog,
} from 'components';
import { logout } from 'lib';
import classNames from 'classnames';
import { useHideDescription, useLocation } from 'hooks';
import { UserType } from 'enums';

interface Props {
  onToggleDrawer?: React.MouseEventHandler<HTMLElement>;
  routes?: AppRouteProps[];
}

export const Header: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const [user, setUser] = React.useState<User>();
  const [shops, setShops] = React.useState<ShopAllocation[]>([]);
  const [userLocations, setUserLocations] = React.useState<
    LocationAllocation[]
  >([]);
  const [shopId, setShopId] = React.useState<number>();
  const [location, setLocation] = useLocation();
  const [open, setOpen] = React.useState(location === undefined ? true : false);
  const [openLanguage, setOpenLanguage] = React.useState(false);
  const [openLocations, setOpenLocations] = React.useState(false);
  const [locations, setLocations] = React.useState<ShopLocation[]>([]);
  const [hideDescription, setHideDescription] = useHideDescription();

  const shopLocationId =
    location && location.length > 0
      ? location[0].firebaseShopLocationId
      : undefined;

  React.useEffect(() => {
    UserService.getUserWithToken().then((response) => {
      setUser(response);
    });
  }, []);

  React.useEffect(() => {
    if (shopLocationId) return;
    UserService.getUserWithToken().then((response: User) => {
      setShops(response?.userShopAllocations ?? []);
      setUserLocations(response?.userLocationAllocations ?? []);
    });
  }, [shopLocationId]);

  React.useEffect(() => {
    if (!shopId) return;

    if (user?.userType.id !== UserType.Manager) {
      setLocations(
        userLocations
          .filter((userLocs) => userLocs.shopLocation.shopId === shopId)
          .map((userLoc) => userLoc.shopLocation),
      );
    }
  }, [shopId, user, userLocations]);

  React.useEffect(() => {
    if (!shopId) return;

    if (user?.userType.id === UserType.Manager) {
      ShopService.getLocationsByShipId(shopId).then(
        (response: ShopLocation[]) => {
          setLocation(response);
        },
      );
    }
  }, [setLocation, shopId, user]);

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.title}>
            {user ? (
              <UserAvatar user={user} showLocation name />
            ) : (
              <Typography variant="h6" color="inherit">
                Welcome
              </Typography>
            )}
          </div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={hideDescription === 0}
                  onChange={(e) => setHideDescription(e.target.checked ? 0 : 1)}
                  name="show-description"
                  color="secondary"
                />
              }
              label="Show Description"
            />
          </FormGroup>
          <Button
            onClick={() => {
              setOpenLanguage(true);
            }}
            className={classes.button}
          >
            Change Language
          </Button>
          <Button
            onClick={() => {
              setLocation(undefined);
              setOpen(true);
            }}
            className={classes.button}
          >
            Change Location
          </Button>
          <Button
            onClick={() => {
              setLocation(undefined);
              logout();
            }}
            className={classNames(classes.button, classes.signOutButton)}
          >
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>

      <ShopChooserDialog
        dialogProps={{ open: open, onClose: () => setOpen(false) }}
        shops={shops ?? []}
        onSelect={(id: number) => {
          if (!user) {
            return;
          }
          setShopId(id);

          if (user.userType.id !== UserType.Manager) {
            setOpenLocations(true);
          }

          setOpen(false);
        }}
      />
      <SelectLanguageDialog
        dialogProps={{
          open: openLanguage,
          onClose: () => setOpenLanguage(false),
        }}
      />
      <LocationChooserDialog
        dialogProps={{
          open: openLocations,
          onClose: () => setOpenLocations(false),
        }}
        locations={locations ?? []}
        onSelect={(location: ShopLocation) => {
          const locations: ShopLocation[] = [location];
          setLocation(locations);
          setOpenLocations(false);
          setOpen(false);
        }}
      />
    </React.Fragment>
  );
};
