import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as React from 'react';

interface Props {
  title: string;
  dialogProps: DialogProps;
  loading?: boolean;
  content?: React.ReactNode;
  noText?: string;
  yesText?: string;
  onConfirm?(e: React.MouseEvent<HTMLElement>): void;
}

export const ConfirmDialog: React.FC<Props> = ({
  title,
  dialogProps,
  loading = false,
  content,
  noText = 'No',
  yesText = 'Yes',
  onConfirm,
}) => (
  <Dialog {...dialogProps}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>
      {typeof content === 'string' ? (
        <DialogContentText>{content}</DialogContentText>
      ) : (
        content
      )}
    </DialogContent>

    <DialogActions>
      <Button onClick={dialogProps.onClose} color="default" disabled={loading}>
        {noText}
      </Button>

      {onConfirm && (
        <Button
          onClick={(e) => {
            if (dialogProps.onClose) {
              dialogProps.onClose(e);
            }

            onConfirm(e);
          }}
          color="primary"
          disabled={loading}
          autoFocus
        >
          {yesText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
