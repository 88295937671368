import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
    transform: 'unset',
    zIndex: 1,
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  button: {
    backgroundColor: theme.palette.common.white,
    wordBreak: 'break-word',
    width: '85px',
    marginLeft: theme.spacing(1),
    height: '50px',
    color: theme.palette.primary.main,
    lineHeight: 1.3,
  },
  signOutButton: {
    padding: '0px 20px',
    color: theme.palette.danger.main,
  },
}));
