import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@material-ui/core';
import * as React from 'react';
import { SnackbarContext } from '../../components';
import { useStyles } from './styles';

interface ClientInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export const Register: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);
  const classes = useStyles();

  const [form, setForm] = React.useState<ClientInput>(getInitialForm());

  return (
    <form onSubmit={submit}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title="Let's get started"
          classes={{ title: classes.cardHeaderTitle }}
        />

        <CardContent>
          <Grid container>
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="First name"
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  type="text"
                  required
                  InputLabelProps={{
                    shrink: form.firstName !== '',
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Last name"
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  type="text"
                  required
                  InputLabelProps={{
                    shrink: form.lastName !== '',
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Your email address"
                  name="emailAddress"
                  value={form.emailAddress}
                  onChange={handleChange}
                  type="email"
                  required
                  InputLabelProps={{
                    shrink: form.emailAddress !== '',
                  }}
                />
              </Grid>
            </React.Fragment>
          </Grid>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            type="submit"
            size="medium"
            color="primary"
            className="agree-and-signup"
            variant="contained"
          >
            Register
          </Button>
        </CardActions>
      </Card>
    </form>
  );

  function getInitialForm() {
    return {
      firstName: '',
      lastName: '',
      emailAddress: '',
    };
  }

  function handleChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name } = target;
    let { value } = target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));
  }

  async function submit() {
    try {
      snackbar.success('TODO: Implement Register!');
    } catch (error) {
      snackbar.open(error);
    }
  }
};
