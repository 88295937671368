import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Preparation, User } from 'interfaces';
import { CardActionArea } from '@material-ui/core';
import { UserAvatar, ConfirmDialog } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  complete: {
    color: theme.palette.secondary.main,
  },
  progress: {
    color: theme.palette.danger.main,
  },
  notStarted: {
    color: theme.palette.warning.main,
  },
  tab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
}));

interface Props {
  preparation: Preparation;
  currentPreparation: Preparation;
  changePreparation: (batch: Preparation) => void;
  user: User;
}

export const PreparationCard: React.FC<Props> = ({
  preparation,
  currentPreparation,
  changePreparation,
  user,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const date = new Date(preparation.created);

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() =>
          getResponse(
            preparation,
            currentPreparation,
            setDialog,
            changePreparation,
            user,
          )
        }
      >
        <CardContent className={classes.flex}>
          <UserAvatar fullName={preparation.assignee_name} />
          <div className={classes.flexColumn}>
            <Typography variant="h5">{preparation.name}</Typography>
            <Typography variant="subtitle2">
              {`Preparation created at: ${date.toLocaleTimeString()}`}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <ConfirmDialog
        title={text}
        dialogProps={{ open: open, onClose: () => setDialog('', false) }}
        noText="Ok"
      />
    </Card>
  );

  function setDialog(text: string, open: boolean) {
    setText(text);
    setOpen(open);
  }

  function getResponse(
    preparation: Preparation,
    currentPreparation: Preparation,
    setDialog: (text: string, open: boolean) => void,
    changePreparation: (preparation: Preparation) => void,
    user: User,
  ): void {
    if (currentPreparation.start) {
      setDialog(
        'Please finish your current batch before starting a new one',
        true,
      );
      return;
    }

    if (preparation.shop_location_id !== currentPreparation.shop_location_id) {
      setDialog(
        'This batch is from another location, please start another batch',
        true,
      );
      return;
    }
    if (preparation.start && preparation.assignee_id !== user.id) {
      setDialog(
        'This batch is already in progress by someone else, please start another batch',
        true,
      );

      return;
    }

    changePreparation(preparation);
    return;
  }
};
