import * as React from 'react';
import { Translation } from 'interfaces';
import { Button, ButtonProps } from '@material-ui/core';
import { translate } from 'lib';

interface Props {
  buttonText: string;
  translations: Translation[];
  buttonProps: ButtonProps;
}

export const TranslatedButton: React.FC<Props> = ({
  buttonText,
  translations,
  buttonProps,
}) => <Button {...buttonProps}>{translate(translations, buttonText)}</Button>;
