import { Button, CardActions, CardContent, TextField } from '@material-ui/core';
import { RouterLink, SnackbarContext } from 'components';
import * as React from 'react';
import { Redirect, useParams } from 'react-router';

export const ResetPassword: React.FC = () => {
  // TODO: use code
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { code } = useParams<{ code: string }>();

  const snackbar = React.useContext(SnackbarContext);

  const [state, setState] = React.useState({
    password: '',
    confirmPassword: '',
    redirect: false,
  });

  const { redirect, password, confirmPassword } = state;

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <form onSubmit={submit}>
      <CardContent>
        <TextField
          fullWidth
          margin="normal"
          label="New Password"
          name="password"
          value={password}
          onChange={handleChange}
          type="password"
          autoComplete="new-password"
          required
        />

        <TextField
          fullWidth
          margin="normal"
          label="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          type="password"
          autoComplete="new-password"
          required
        />
      </CardContent>

      <CardActions style={{ justifyContent: 'space-between' }}>
        <Button
          type="button"
          size="small"
          color="default"
          component={RouterLink}
          to="/login"
        >
          Cancel
        </Button>

        <Button type="submit" size="small" color="primary" variant="contained">
          Save password
        </Button>
      </CardActions>
    </form>
  );

  function handleChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = target;

    setState((s) => ({ ...s, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (password !== confirmPassword) {
        throw new Error('Passwords dont match');
      }

      snackbar.open('TODO: IMplement: Password has been changed');

      setState((s) => ({ ...s, redirect: true }));
    } catch (error) {
      snackbar.open(error);
    }
  }
};
